@import 'src/styles/imports';

.label {
  font-size: .75em;
  overflow: hidden;
  padding-right: 3em;
  margin-top: .5em;
  color: $color-grey-12;
  font-weight: 500;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;

  &:hover {
    white-space: normal;
  }

  .blur {
    color: $color-grey-9;
    filter: blur(.25em);
  }
}

.no-logo {
  max-width: 100%;
}

.has-logo {
  max-width: 90%;
}

@import 'src/styles/imports';

$icon-middle-height: .8em; // affects .tag
$icon-right-height: 2em;
$icon-middle-height-exp: 1em; // affects .tag

.component {
  font-size: 1.125em;
  position: relative;
  height: 100%;

  .icon-middle {
    position: absolute;
    top: calc(50% - $icon-middle-height/2);
    right: 3.2em;
    color: $color-grey-5;
    cursor: pointer;

    &.exp-icon-middle {
      top: calc(50% - $icon-middle-height-exp/2);
      right: 2.5em;
    }
  }

  .icon-right {
    position: absolute;
    top: calc(50% - $icon-right-height/2);
    right: .25em;
    overflow: hidden;
    height: $icon-right-height;
    border-radius: var(--themes-icons-border-radius);
    cursor: pointer;

    &.exp-theme {
      svg {
        width: 2em;
      }
    }

    svg {
      width: 100%;
      height: $icon-right-height;
    }
  }

  .icon-input {
    font-size: .875em;
    height: 100%;
    padding: .5em .8em;
    border: solid 1px $color-grey-5;
    border-radius: var(--themes-icons-border-radius);

    &.icon-input-middle {
      padding-right: 2em;
    }

    &.icon-input-right {
      padding-right: 4em;
    }
  }

  &.padded {
    .icon-input {
      padding: 15px;
    }

    .icon-right {
      right: .45em;
    }
  }

  .tag {
    font-size: .75em;
    position: absolute;
    top: 15%;
    left: .5em;
    display: flex;
    height: 70%;
    padding: 0 .8em;
    border-radius: 2em;
    background-color: $color-grey-4;
    font-weight: $font-weight-bold;
    white-space: nowrap;
    align-items: center;

    i {
      margin-left: .2em;
    }
  }

  .icon-middle,
  .tag {
    svg {
      width: .8em;
      height: $icon-middle-height;
      margin-left: .5em;
    }
  }

  .exp-icon-middle,
  .exp-tag {
    svg {
      height: $icon-middle-height-exp;
    }
  }
}

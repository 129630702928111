@import 'src/styles/imports';

.component {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .cta-button {
    width: 5.5em;
    border-radius: var(--themes-buttons-border-radius);
  }

  .disclaimer {
    line-height: 1.5em;
    margin-bottom: 1em;
  }
}

.component-regular {
  min-height: 6.5em;
  padding-top: .8em;
}

.component-small {
  min-height: 5em;
  padding-top: .8em;

  .disclaimer {
    font-size: .9em;
    line-height: 1.2em;
  }

  .cta-button {
    margin-top: .5em;
  }
}

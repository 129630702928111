// --------- Widths --------
$max-width-app: 1200px;
$max-width-text: 900px;
$width-advanced-search-dropdown: 73%;
$width-hover-popup: 280px;
$min-width-listing-card: 264px;

// -------- Heights --------
$height-top-nav: 80px;
$height-top-nav-mobile: 80px;
$height-bottom-nav-mobile: 80px;
$height-exp-top-nav: 60px;
$height-exp-top-nav-mobile: 80px;
$height-top-nav-panel: 170px;
$height-hero-hav-subnav: 60px;
$height-input-control: 35px;
$height-filter-inputs: 60px;
$height-advanced-search: 54px;
$height-ad-unit: 114px;
$body-min-height: calc(100vh - #{$height-top-nav});
$small-window-height: 900px;
$large-window-height: 1200px;

// --------- General --------
$full-width-side-padding: 2.5%;

// ------ Animations -------
$transition-duration-fast: .2s;
$transition-duration:      .4s;
$transition-duration-slow: .8s;

// --------- Border --------
$border: 1px solid $color-grey-3;
$border-darker: 1px solid $color-grey-4;

// ----- Border Radius -----
$border-radius-small:  2px;
$border-radius-medium: 4px;
$border-radius-large:  8px;
$border-radius-xlarge: 100px;
$border-radius-agent-image-mobile: 16px;
$border-radius-agent-image: 24px;
$border-radius-pill: 64px;

// ------ box-shadow -------
$box-shadow-top: 0 0 4px 0 $color-black-25;
$box-shadow: 0 2px 4px 0 $color-black-25;
$box-shadow-spread: 0 1px 2px 1px $color-black-35;
$box-shadow-dense: 0 1px 4px 1px $color-black-15;
$box-shadow-listing-card: 0 2px 6px 0 $color-black-15;
$box-shadow-popup: 0 2px 15px rgba(0, 0, 0, .18);
$box-shadow-mobile-map-button: 0 1px 4px 0 rgba(0, 0, 0, .18);
$box-shadow-map-card: 0 1px 4px 0 rgba(0, 0, 0, .01), 0 4px 8px 0 rgba(0, 0, 0, .02), 0 1px 12px 0 rgba(0, 0, 0, .12);
$box-shadow-map-callout: 0 1px 4px 0 rgba(0, 0, 0, .01), 0 4px 8px 0 rgba(0, 0, 0, .02), 0 1px 12px 0 rgba(0, 0, 0, .12);

// ------ text-shadow ------
$text-shadow: 2px 0 9px $color-black-50;
$text-shadow-darker: 0 1px 4px $color-black;

// ------ Breakpoints ------
$breakpoint-mobile: 480px;
$breakpoint-large-mobile: 600px;
$breakpoint-tablet: 768px;
$breakpoint-large-desktop: 1920px;
$breakpoint-top-nav: $breakpoint-tablet;
$breakpoint-ad-leaderboard: $breakpoint-top-nav; // grater than 728px padding on both sides
$breakpoint-search-mobile: 758px; // TODO: Change this back to 500px
$breakpoint-side-panel: 1199px;

// -------- Other ----------
$ios-controls-height: 74px;
$ios-vertical-height: calc(100vh - #{$ios-controls-height});

// ----- Page Specific -----
// ------ Search Page ------
$width-side-panel-small: 310px;
$width-side-panel-enlarged: 605px;
$height-exp-top-nav-offset: 143px; // Used by both side panel and map
$height-zoo-top-nav-offset: 163px; // Used by both side panel and map
$height-search-header-bar: 40px;
$height-search-inputs-bar: 66px;
$height-search-header-bar-mobile: 54px;
$height-search-mobile-footer: 44px;
$top-search-offset: $height-search-header-bar + $height-search-inputs-bar;

// ------ Listing Page -----
$max-width-listing-page: 1000px;
$box-shadow-listing-page: 0 1px 10px $color-grey-3;

// ------ Agent Search Page -----
$breakpoint-min-3-columns: 1100px;
$breakpoint-min-4-columns: 1300px;

// ------ Saved Search Card -----
$box-shadow-saved-search-card: 0 2px 4px 0 $color-grey-2;

$ball-height: 20px;
$bar-height: 8px;

@import 'src/styles/imports';

.component {
  font-size: 9.5pt;
  position: relative;
  display: block;
  padding: .75em 0;
  margin-right: 1.5em;
  text-align: left;

  input {
    display: none;
  }

  &,
  label {
    cursor: pointer;
  }

  label {
    display: inline-block;
    line-height: 1.3;
    padding-left: 2em;

    a {
      text-decoration: underline;
    }

    p,
    a {
      font-size: inherit !important; // sass-lint:disable-line no-important
    }
  }

  .checkmark {
    position: absolute;
    top: 1em;
    left: 2.7pt;
    width: .9em;
    height: .9em;
    margin: 0;
    transform: scale(0);
    transition: $transition-duration-fast;

    path {
      fill: var(--themes-colors-primary);
    }
  }

  &::before {
    content: '';
    font-size: 1.3em;
    position: absolute;
    left: 0;
    width: 18px;
    height: 18px;
    line-height: 18px;
    border: 1px solid $color-grey-3;
    border-radius: $border-radius-small;
    background-color: $color-white;
    pointer-events: none;
    transition: $transition-duration-fast;
  }

  &.error {
    &::before {
      border-color: $color-red;
    }
  }
}

.active {
  .checkmark {
    transform: scale(1);
  }
}

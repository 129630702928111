@import 'src/styles/imports';

.component {
  position: relative;
  width: 100%;

  svg {
    position: absolute;
    top: calc(50% - .4em);
    right: .5em;
    pointer-events: none; 
  }
}

.select {
  width: 100%;
  min-height: 50px;
  padding: .75em 1.5em .75em .75em;
  border: 1px solid $color-grey-3;
  background: $color-white;
  font-family: $font-family-sans-serif;
  text-overflow: ellipsis;
  text-transform: capitalize;
  cursor: pointer;
  appearance: none;
}

.select:focus + svg {
  transform: rotate(180deg) !important;
  transition: all .3s ease;
}

.label {
  font-size: .75em;
  line-height: 2em;
}

.wrong {
  border-color: $color-red;
}

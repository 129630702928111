@import 'src/styles/imports';

.wrapper {
  padding-bottom: 10px;
}

.wrapper-small {
  padding-top: 18px;
  padding-bottom: 10px;
}

.component {
  position: absolute;
  right: 5px;
  bottom: -7px;
  
  .provider-logo {
    img {
      position: relative;
      max-width: 3em;
      max-height: 1.5em;
      object-fit: contain;
    }
  }
}

@import 'src/styles/imports';

.row {
  display: flex;
  margin-top: .2em;
  align-items: center;
  justify-content: space-between;
}

.listing-detail {
  font-size: .75em;
  display: inline-flex;
  margin-right: 0.6em;
  margin-bottom: .25em;
  color: var(--themes-colors-primary-dark);
  align-items: center;

  &:last-of-type {
    margin-right: 0;
  }

  .icon {
    margin-right: .3em;
  }
}

.sign-in {
  font-size: .875em;
  margin-top: .4em;
}

.arrow-up {
  width: 0;
  height: 0;
  border-right: 5px solid transparent;
  border-bottom: 10px solid $color-green;
  border-left: 5px solid transparent;
  margin-left: .25em;
}

.arrow-down {
  width: 0;
  height: 0;
  border-top: 10px solid $color-red;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  margin-bottom: -.15em;
  margin-left: .25em;
}

.exp {
  .listing-detail {
    font-size: 14px;
    font-weight: 500;
  }
}

@import 'src/styles/imports';

$filters-left-side-width: 90%;

.component {
  display: flex;
  height: 5.2em;
  padding: 1.2rem 0;
  border-bottom: 1px solid $color-grey-2;
  align-items: center;
  justify-content: space-between;
}

.filters-container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;

  @media (max-width: $breakpoint-tablet) {
    display: none;
  }

  button {
    height: 100%;
  }

  .filters-left-container {
    display: flex;
    width: $filters-left-side-width;
    flex-direction: row;

    .custom-panel-style {
      overflow: visible; // Shows another absolutely positioned panel on price range
    }
  }

  .filters-right-container {
    width: 1 - $filters-left-side-width;
    margin-left: 1em;
  }
}

.search-container {
  display: flex;
  width: 100%;
  max-width: 500px;
  height: 100%;
  margin-right: .4em;

  button {
    height: 100%;
  }
}

.mobile-search {
  display: none;
  width: 100%;
  border: 1px solid $color-grey-3;
  border-radius: $border-radius-medium;

  @media (max-width: $breakpoint-tablet) {
    display: block;
  }
}

.middle-filters {
  display: flex;

  @media (max-width: 1190px) {
    display: none;
  }
}

.search-button {
  font-size: .875em;
  display: block;
  padding: 0 1em;
  border-radius: $border-radius-small;
  margin: 0 1em 0 .5em;
  background: $color-sand;
  color: $color-white;
}

.location-search-container {
  position: relative;
  display: block;
  width: 100%;
  margin-right: .4em;
}

.more-filters-button {
  font-size: .875em;
  min-height: 40px;
  line-height: 1;
  padding: 0 1em;
  border: 1px solid $color-black;
  border-radius: $border-radius-small;
  margin: 0 .4em;
  color: $color-black;
}

.save-search {
  margin-left: .5em;
}

.padded-button {
  margin: 0 .5em;

  .more-filters-button-wrapper {
    position: relative;
    height: 100%;
  }
}

.mobile-component {
  position: fixed;
  z-index: $z-index-fixed-footer;
  bottom: 10px;
  left: calc(50% - 41px);
  display: flex;
  width: 83px;
  max-width: 55em;
  height: 38px;
  padding: 10px 16px;
  border-radius: 8px;
  background-color: $color-grey-6;
  color: var(--themes-colors-primary-font-color);
  align-items: center;
  justify-content: space-between;

  .icon {
    width: 30px;
    height: 30px;
    fill: var(--themes-colors-primary-font-color);
    }
}

.mlml-create-custom {
  max-width: 260px;
}

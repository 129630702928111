@import 'src/styles/imports';

.component {
  position: absolute;
  top: 0;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;

  &.black {
    background-color: $color-black;
  }

  .new-overlay-text {
    position: absolute;
    line-height: 28px;
    padding: 12px 20px;
    border-radius: var(--themes-buttons-border-radius);
    background-color: $color-white;
    text-align: center;
    transition: all 0.2s ease-in-out;

    &.zoocasa-theme {
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);

      &:hover {
        background-color: $color-warm-black;
        color: $color-white;
      }

      &:focus {
        background-color: $color-warm-black-80;
        color: $color-white;
      }
    }
  }

  .small-text {
    font-size: .85em;
  }
}

@import 'src/styles/imports';

.component {
  width: 100%;

  .price-inputs {
    display: flex;
    padding-bottom: 1em;
    align-items: center;

    small {
      margin: 0 .5em;
    }

    .input-container {
      position: relative;

      .custom-input-container { // overwrites default margin-top on <TextField>
        margin-top: 0;
      }

      input {
        font-size: 12pt;
        width: 100px;
        padding: .4em;
        outline: none;
      }

      input:focus {
        border: 1px solid var(--themes-colors-primary-filters);
      }
    }
  }

  .filter-controls {
    width: 100%;
  }

  &.dark {
    .price-options {
      span {
        color: $color-white;

        &:hover {
          color: $color-white;
          font-weight: $font-weight-bold;
        }

        &.active-option {
          color: $color-white;
          font-weight: $font-weight-bold;
        }
      }
    }
  }
}

.price-options {
  position: absolute;
  z-index: $z-index-bump;
  top: 3.4em;
  display: none;
  min-width: 122px;
  padding: 0 1em;
  border: 1px solid $color-grey-3;
  border-radius: var(--themes-buttons-border-radius-small);
  background-color: $color-white;

  span {
    font-size: 12pt;
    display: block;
    margin: .7em 0;
    color: $color-grey-6;

    &:hover {
      color: $color-black;
      font-weight: $font-weight-bold;
      cursor: pointer;
    }

    &.active-option {
      color: $color-black;
      font-weight: $font-weight-bold;
    }
  }

  &.up-side-down {
    top: unset;
    bottom: 3.4em;
  }

  &.active {
    display: block;
  }
}


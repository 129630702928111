@import 'src/styles/imports';

.component {
  position: absolute;
  bottom: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  pointer-events: none;

  .description {
    font-size: 16px;
    position: absolute;
    bottom: 0;
    overflow: hidden;
    box-sizing: border-box;
    width: 100%;
    max-height: 40px;
    line-height: 150%;
    padding: 8px 18px;
    background-color: $color-black-50;
    color: $color-white;
    font-weight: 500;
    text-align: center;
    text-overflow: ellipsis;
    letter-spacing: .44px;
    white-space: nowrap;
    transition: all .8s ease-in-out;
  }

  .description:hover {
    overflow-y: scroll;
    max-height: 55%;
    white-space: normal;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  @media (max-width: $breakpoint-mobile) {
    .description {
      padding: 5px 10px;
    }

    .description:hover {
      max-height: 100%;
    }
  }
}

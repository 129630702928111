@mixin flex-wrap-around {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

@mixin flex-wrap-between {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@mixin flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin flex-around {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

@mixin flex-center {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

@mixin flex-center-vertical {
  display: flex;
  align-items: center;
}

@mixin flex-center-horizontal {
  display: flex;
  justify-content: center;
}

@mixin flex-center-inline {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@mixin flex-col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@mixin layout-container($max-width: $max-width-app, $side-padding: 20px) {
  max-width: $max-width;
  padding-right: $side-padding;
  padding-left: $side-padding;
  margin-right: auto;
  margin-left: auto;
}

@mixin full-width-layout-container($side-padding: $full-width-side-padding) {
  max-width: 2200px;
  padding-right: $side-padding;
  padding-left: $side-padding;
  margin-right: auto;
  margin-left: auto;
}

@mixin layout-columns($columns, $container-columns, $gutter: 2%) {
  $num: $container-columns / $columns;
  display: inline-block;
  width: $columns / $container-columns * 100% - ($gutter * ($num - 1) / $num);
  margin-right: $gutter;

  &:nth-of-type(#{$num}n) {
    margin-right: 0;
  }
}

@mixin mobile {
  @media (max-width: $breakpoint-mobile) {
    @content;
  }
}

@mixin not-mobile {
  @media (min-width: ($breakpoint-mobile + 1)) {
    @content;
  }
}

@mixin large-mobile {
  @media (max-width: $breakpoint-large-mobile) {
    @content;
  }
}

@mixin not-large-mobile {
  @media (min-width: ($breakpoint-large-mobile + 1)) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: ($breakpoint-mobile + 1)) and (max-width: $breakpoint-tablet) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: ($breakpoint-tablet + 1)) {
    @content;
  }
}

@mixin large-desktop {
  @media (min-width: ($breakpoint-large-desktop + 1)) {
    @content;
  }
}

@mixin not-desktop {
  @media (max-width: $breakpoint-tablet) {
    @content;
  }
}

@mixin landscape {
  @media (max-device-width: $breakpoint-tablet) and (orientation: landscape) {
    @content;
  }
}

@mixin mobile-and-landscape {
  @media only screen and (max-height: 500px) and (orientation: landscape) {
    @content;
  }
}

@mixin desktop-container($max-width: $max-width-app, $side-padding: 20px) {
  @include desktop { @include layout-container($max-width, $side-padding) }
}

@mixin tablet-container($max-width: $max-width-app, $side-padding: 20px) {
  @include tablet { @include layout-container($max-width, $side-padding) }
}

@mixin mobile-container($max-width: $max-width-app, $side-padding: 0) {
  @include mobile { @include layout-container($max-width, $side-padding) }
}

@mixin desktop-columns($columns, $container-columns, $gutter: 2%) {
  @include desktop { @include layout-columns($columns, $container-columns, $gutter) }
}

@mixin tablet-columns($columns, $container-columns, $gutter: 2%) {
  @include tablet { @include layout-columns($columns, $container-columns, $gutter) }
}

@mixin mobile-columns($columns, $container-columns, $gutter: 2%) {
  @include mobile { @include layout-columns($columns, $container-columns, $gutter) }
}

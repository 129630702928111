@import 'src/styles/imports';

.component {
  text-align: left;

  .section-title {
    display: flex;
    padding: .75em;
    justify-content: space-between;

    span {
      display: flex;
      align-items: center;

      svg {
        width: 1em;
        height: 1em;
        margin-right: .3em;

        path {
          fill: $color-black !important;
        }
      }
    }
  }

  .powered-by-apple {
    font-size: .5em;
    display: flex;
    color: $color-grey-5;
    align-items: center;

    img {
      margin-left: .5em;
    }
  }

  .powered-by-google {
    display: flex;

    img {
      width: 80%;
      height: auto;
      margin-left: auto;
    }
  }

  .suggested-link .location-option,
  .location-option {
    font-size: .875em;
    position: relative;
    display: flex;
    height: auto;
    line-height: 1.5em;
    padding: .75em 0 .75em .75em;
    margin: 0;
    color: $color-grey-10;
    text-decoration: none;
    text-transform: capitalize;
    cursor: pointer;
    justify-content: space-between;

    &:hover {
      background: $color-grey-2;
      font-weight: $font-weight-bold;
    }

    &.active {
      background: $color-grey-4;
      font-weight: $font-weight-bold;
    }

    strong {
      font-weight: $font-weight-bold;
    }

    .label {
      margin-right: 1em;
      margin-left: 2em;
      white-space: nowrap;

      .sale {
        color: $color-blue;
      }

      .sold {
        color: $color-red;
      }
    }
  }
}

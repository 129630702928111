// --------------- Animation Classes (Public Variables) ---------------
.icon-spin::before {
  animation: spin 1.2s infinite linear;
}

// ------------- Animation Keyframes (Private Variables) --------------
@keyframes spin {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes progressBarStripes {
  0%   { background-position: 0 0; }
  100% { background-position: 24px 0; }
}

@keyframes pulsateScale {
  0%   { transform: scale(1); }
  50%  { transform: scale(1.3); }
  100% { transform: scale(1); }
}

@keyframes smallPulsateScale {
  0%   { transform: scale(1); }
  50%  { transform: scale(1.1); }
  100% { transform: scale(1); }
}

@keyframes pride {
  // sass-lint:disable-block no-color-literals
  12.5% { fill: #ff0; }
  25% { fill: #7fff00; }
  37.5% { fill: #0ff; }
  50% { fill: #00f; }
  62.5% { fill: #9932cc; }
  75% { fill: #ff1493; }
  87.5% { fill: #f00; }
  100% { fill: $color-sand; }
}

@keyframes fillWhite {
  100% { fill: $color-white; }
}

@keyframes shimmer {
  0% { background-position: 100% 0; }
  100% { background-position: -100% 0; }
}

@keyframes dash {
  0% { stroke-dashoffset: 1000; }
  100% { stroke-dashoffset: 0; }
}

@keyframes dash-check {
  0% { stroke-dashoffset: -100; }
  100% { stroke-dashoffset: 900; }
}

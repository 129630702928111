@import 'src/styles/imports';

.component {
  display: flex;
  margin-top: 1em;
  align-items: center;

  .label-left {
    display: flex;
    max-width: 70%;
    align-items: center;

    .icon {
      width: 1em;
      margin-right: .5em;
      flex-shrink: 0;
    }

    label {
      line-height: 1.5em;
      white-space: normal; // Allow text to wrap onto multiple lines
    }
  }

  label {
    font-size: .875em;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 2.8125em;
    height: 1.5em;
    margin-right: .5em;

    input {
      width: 0;
      height: 0;
      opacity: 0;
    }

    span {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 2.125em;
      background-color: $color-grey-3;
      cursor: pointer;
      transition: $transition-duration;
    }

    span::before {
      content: '';
      position: absolute;
      bottom: .125em;
      left: .125em;
      width: 1.25em;
      height: 1.25em;
      border-radius: 50%;
      background-color: $color-white;
      transition: $transition-duration;
    }

    input:checked + span {
      background-color: var(--themes-colors-primary-filters);
    }

    input:checked + span::before {
      transform: translateX(1.3em);
    }
  }
}

.component-full-width {
  width: 100%;
  justify-content: space-between;

  .switch {
    margin-right: 0;
  }
}

@import 'src/styles/imports';

.component {
  font-size: .875em;
  position: relative;
  display: inline-flex;
  min-height: 40px;
  border: 1px solid $color-warm-black-20;
  border-radius: var(--themes-buttons-border-radius);
  margin: 0 .4em;
  color: $color-black;
  text-transform: capitalize;
  cursor: pointer;
  align-items: center;
  transition: all .4s ease-in-out;
  
  &.theme-dark {
    &:hover {
      border: 1px solid $color-warm-black;
    }

    .dropdown svg path {
      fill: $color-black;
    }
  }

  &.zoocasa-theme {
    &.active {
      border: 1px solid $color-warm-black;
      background-color: $color-warm-black-5;

      .dropdown svg {
        transform: rotate(180deg);
        transform-origin: 55% 45%;
        transition: all .4s ease-in-out;
      }
    }
  }

  &.exp-theme {
    &:hover {
      border: 1px solid $color-modern-silver;
    }

    &.active {
      border: 1px solid $color-modern-silver;
      background-color: $color-dark-navy-bg;

      .dropdown svg {
        transform: none;
      }
    }
  }

  &.theme-rounded {
    border: 1px solid $color-grey-3;
    border-radius: var(--themes-buttons-border-radius);
    margin: 0;
    color: $color-grey-10;
  }

  .dropdown {
    display: flex;
    height: 100%;
    padding: 0 .75em;
    white-space: nowrap;
    align-items: center;
    justify-content: center;

    svg {
      margin-top: .3em;
      margin-left: .3em;

      path {
        fill: $color-white;
      }
    }
  }

  .panel {
    position: absolute;
    z-index: $z-index-dropdown;
    top: 3.9em;
    left: 0;
    display: none;
    box-shadow: var(--themes-box-shadow-light);
    min-width: 100%;
    padding: .5em;
    border: 1px solid $color-white;
    border-radius: var(--themes-buttons-border-radius-small);
    background-color: $color-grey-10;
    white-space: nowrap;
  }
}

.active {
  .panel {
    display: block;
    overflow: hidden;
    border-top: 1px solid $color-grey-4;

    &.theme-dark,
    &.theme-rounded {
      border: 1px solid $color-grey-2;
      background-color: $color-white;
      color: $color-black;
    }
  }
}

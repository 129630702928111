@import 'src/styles/imports';

.component {
  position: relative;
  display: block;
  width: 100%;

  .location-search {
    &:hover {
      border-color: var(--themes-colors-primary-border-color) !important;
    }

    &.zoocasa-theme {
      background-color: var(--themes-colors-tertiary-background) !important;

      &:focus {
        border-color: $color-sand !important;
      }
    }
  }
}

// sass-lint:disable no-important
@import 'src/styles/imports';
@import 'src/styles/animations';

.component {
  display: flex;
  min-width: 3em;
  min-height: 3em;
  border-radius: 50%;
  background-color: $color-black-65;
  align-items: center;
  justify-content: center;

  .favourite {
    font-size: initial;
    position: initial;
    width: 1.5em;
    height: 1.5em;

    g {
      stroke-width: 2;

      path {
        fill: $color-transparent;
      }
    }
  }

  label {
    cursor: pointer;
  }
}

.small-card.favourite-wrapper {
  font-size: 1em;
  position: relative;
  top: -10px;
  right: auto;
  box-shadow: unset;
  height: 1.18em;

  .favourite path {
    stroke: $color-black;
    stroke-width: 1;
  }
}

.address-page-icon.favourite-wrapper {
  position: unset;
  background-color: $color-black-65;

  path {
    fill: $color-white;
    stroke: $color-white;
  }

  .exp {
    path {
      stroke: var(--themes-colors-primary-font-color);
    }
  }
}

.favourite-wrapper {
  position: absolute;
  z-index: $z-index-bump;
  top: -20px;
  right: 5px;
  box-shadow: var(--themes-box-shadow-light);
  width: 40px;
  height: 40px;
  border-radius: 50px;
  background: $color-white;
}

.favourite {
  display: flex;
  height: 1.3em;
  margin: 10px auto;
  cursor: pointer;
  align-items: center;
  justify-content: center;

  path {
    stroke: $color-black;
    stroke-width: 2;
  }

  &:hover {
    animation: smallPulsateScale 1s ease-in-out infinite;
  }

  &.active {
    g {
      stroke: $color-orange;

      path {
        fill: $color-orange;
        stroke: $color-orange !important;
      }
    }

    &.exp {
      g {
        stroke: $color-exp-maroon;

        path {
          fill: $color-exp-maroon;
          stroke: $color-exp-maroon !important;
        }
      }
    }
  }
}

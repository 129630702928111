@import 'src/styles/imports';

.component {
  position: relative;
  display: flex;
  margin-top: 1em;
  flex-direction: column;

  &.hidden {
    display: none;
  }

  &.inline-label {
    align-items: center;
    flex-direction: row;

    label {
      width: 8em;
      word-wrap: break-word;
    }

    input {
      min-width: 18em;
    }

    input:disabled {
      background-color: $color-grey-1;
    }

    span {
      position: absolute;
      top: -1.4em;
    }


    @media (max-width: 481px) {
      label {
        width: 7em;
        word-wrap: break-word;
      }

      input {
        min-width: 17em;
      }
    }
  }

  label {
    font-size: 1em;
    padding: .3em;
    font-family: $font-family-serif;
  }

  .input-container {
    position: relative;

    &.currency-icon input {
      padding-left: 1.7em !important;
    }

    &.currency-icon::before {
      content: '$';
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
    }
  }

  .eye {
    position: absolute;
    top: calc(50% - 1.5em);
    right: 1em;
    width: 1.2em;
    height: 1.2em;
    cursor: pointer;
  }

  input[type='text'],
  input[type='email'],
  input[type='password'] {
    &.error {
      border-color: $color-red;
    }
  }

  .error-message {
    font-size: .8125em;
    position: absolute;
    top: -20px;
    left: 0;
    padding-top: .3em;
    color: $color-red;

    svg {
      margin: 0;
    }
  }

  .error-message-with-label {
    padding-top: 2.2em;
  }
}

// sass-lint:disable no-important
@import 'src/styles/imports';

.component {
  position: relative;
  display: flex;
  flex: 1;

  &.floating {
    position: absolute;
    z-index: $z-index-1;
    top: 1.5em;
    right: 1.5em;

    @include mobile {
      top: .5em;
      right: .5em;
    }

    &.not-visible {
      opacity: 0;
      pointer-events: none;
    }
  }

  .button {
    width: 100%;
    height: 100%;
    border: 1px solid $color-exp-primary-brand-royal;
    background-color: $color-exp-primary-brand-royal !important;
    color: $color-white !important;

    path {
      fill: $color-white !important;
    }

    &:hover {
      border: 1px solid $color-exp-primary-brand-royal;
      opacity: .92;
    }
  }

  .popover {
    position: absolute;
    z-index: $z-index-1;
    top: 120%;
    right: 0;
    box-shadow: var(--themes-box-shadow-light);
    min-width: 370px;
    padding: 18px;
    border-radius: var(--themes-buttons-border-radius-medium);
    background-color: $color-white;

    h2 {
      font-size: 1.5em;
      line-height: 1.3em;
      margin-bottom: 15px;
    }

    p {
      font-size: .875em;
      line-height: 1.2em;
      margin-bottom: 1em;
    }
  }
}

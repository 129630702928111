@import 'src/styles/imports';

.component {
  margin: 2em 0;

  &.area-disclaimer {
    margin-right: .5em;
    margin-left: .5em;
  }

  p {
    font-size: 13px;
    margin-top: 1em;
  }

  .courtesy {
    margin-bottom: .5em;
  }

  .disclaimer {
    font-size: 13px;
    line-height: 1.3em;
    margin-top: 1em;

    a {
      color: var(--themes-colors-primary-link-color);
      font-weight: bold;
      
      &:focus {
        color: var(--themes-colors-primary-focus-link-color);
        text-decoration: underline;
      }
    }
  }

}

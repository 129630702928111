.component {
  display: flex;
  margin: .8em 0;
  align-items: center;

  div {
    width: auto;
  }

  .provider-logo {
    width: 7.5em;
  }

  .crea-logo {
    img {
      width: 125px;
      margin-right: 1em;
    }
  }

  .realtor-logo {
    width: 2em;
  }

  .mls-logo {
    width: 3.6em;
    margin-left: 1em;
  }
}

.component {
  font-size: .75em;
  min-width: 50px;
  color: var(--themes-colors-primary-very-dark);
  font-weight: 700;
  text-align: right;
  text-transform: capitalize;

  &.exp {
    font-weight: 500;
  }
}

// --------------- Z-Index Scale (Private Variables) ---------------
$z-index-sub:  -1;
$z-index-0:     0;
$z-index-bump: 1;
$z-index-1:    100;
$z-index-2:    200;
$z-index-3:    300;
$z-index-4:    400;
$z-index-5:    500;
$z-index-6:    600;
$z-index-7:    700;
$z-index-8:    800;
$z-index-9:    900;
$z-index-10:   1000;
$z-index-11:   1100;

// ------------ Z-Index Applications (Public Variables) ------------
// ------------- ordered by priority from top to bottom ------------
$z-index-location-picker-screen:        $z-index-11;
$z-index-terms-of-use:                  $z-index-11;
$z-index-chat-widget:                   $z-index-10;
$z-index-learn-more-form:               $z-index-10;
$z-index-modal-dialog:                  $z-index-9;
$z-index-panel:                         $z-index-9;
$z-index-filters:                       $z-index-9;
$z-index-app-banner:                    $z-index-8;
$z-index-dropdown:                      $z-index-8;
$z-index-full-screen-background:        $z-index-8;
$z-index-brush-stroke:                  $z-index-8;
$z-index-fixed-footer:                  $z-index-7;
$z-index-header:                        $z-index-7;
$z-index-exp-header-text:               calc($z-index-header + $z-index-sub);
$z-index-sign-in-overlay:               $z-index-6;
$z-index-map-header:                    $z-index-3;
$z-index-map-panel:                     $z-index-2;
$z-index-close:                         $z-index-1;
$z-index-pvr-button:                    $z-index-1;
$z-index-listing-popover:               $z-index-1;
$z-index-home-module-swiper:            $z-index-0;

@import 'src/styles/imports';

.component {
  min-width: 270px;

  .button-group {
    box-shadow: none;
    padding: 0 .05em;
    border: 2px solid $color-grey-1;
    border-radius: var(--themes-buttons-border-radius);

    .button-style {
      font-size: 13px;
      width: 5.85em;
      height: 2em;
      border: 1px solid $color-grey-1;
      border-radius: var(--themes-buttons-border-radius);
      margin: .125em;
      background-color: $color-white;
      color: $color-grey-6;
      font-weight: 500;
      text-transform: none;
    }

    .button-style-selected {
      border: 0;
      background-color: var(--themes-colors-primary-filters);
      color: $color-white;
    }
  }

  .button-group-small {
    padding: 0;
    border: 1px solid $color-grey-1;
    border-radius: var(--themes-buttons-border-radius);

    .button-style {
      width: 3em;
      border-radius: 1px;
      margin: 0;
    }
  }

  .button-group-large {

    .button-style {
      width: 9em;
    }
  }

  &.exp-theme {
    .button-group {
      padding: 1px;
      border: 1px solid $color-grey-1;

      .button-style {
        width: auto;
        height: unset;
        padding-right: 1.3em;
        padding-left: 1.3em;
        border: 0;
        border-radius: var(--themes-buttons-border-radius);
      }
    }

    .button-group-small {
      .button-style {
        width: 3em;
      }
    }
  }
}

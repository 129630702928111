@import 'src/styles/imports';

.component {
  font-size: .63em;
  display: inline-flex;
  padding: .5em 1em;
  border-radius: 2em;
  background-color: var(--themes-colors-primary-sold);
  color: $color-white;
  white-space: nowrap;
  align-items: center;

  @include desktop {
    position: initial;
  }

  p {
    font-weight: $font-weight-regular;
  }

  &.for-sale,
  &.for-rent {
    border: 1px $color-grey-16 solid;
    background-color: var(--themes-colors-primary-for-sale);
    color: var(--themes-colors-primary-font-color);
  }

  &.expired {
    background-color: $color-grey-4;
    color: $color-grey-10;
  }

  &.inactive {
    background-color: $color-black;
  }

  &.terminated {
    background-color: $color-red;
  }

  &.extended {
    background-color: var(--themes-colors-primary-sold);
  }
}

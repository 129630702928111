// sass-lint:disable no-important
@import 'src/styles/imports';

.component {
  position: relative;
  display: flex;
  overflow: hidden;
  width: calc(100% - 2px);
  min-height: 100%;
  border: 1px solid $color-grey-1;
  border-radius: var(--themes-buttons-border-radius-medium);
  cursor: pointer;
  flex-direction: column;
  transition: .3s ease;

  &.exp {
    .street-address {
      color: var(--themes-colors-primary-dark);
      font-weight: 600;
    }
  }

  &:hover {
    .image {
      overflow: hidden;
      transform: scale(1.07);
    }
  }

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: blur(0) !important; // Note: Disable the filter on the next image svg inline styling to blur edges
  }

  .image,
  .private {
    border-top-left-radius: var(--themes-buttons-border-radius-medium);
    border-top-right-radius: var(--themes-buttons-border-radius-medium);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    pointer-events: none;
    transition: .3s ease;
  }

  .blur-image {
    filter: blur(4px) !important; // Note: Blur on private listings
  }

  .tag {
    padding: 5px 8px;
    margin-bottom: 10px;
  }

  .wrapper {
    display: flex;
    height: 90%;
    min-height: 7.5em;
    padding: .4em .8em;
    flex-direction: column;

    div {
      img {
        filter: none;
      }
    }
  }

  .bottom-content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .row {
    display: flex;
    overflow: hidden;
    cursor: pointer;
    align-items: center;
    justify-content: space-between;
  }

  .street-address {
    font-size: .9375em;
    margin: .5em 0;
  }

  .pvr-date {
    font-size: 9px;
    position: absolute;
    top: 10px;
    left: 10px;
    padding: .5em 1em;
    border-radius: 50px;
    background-color: $color-white-80;
    font-weight: 600;
  }
}

.component {
  min-width: 210px;
  padding: 0 1em .5em;

  .indented {
    margin-left: 1em;
  }

  .shown {
    height: 100%;
  }

  .hidden {
    display: none;
  }
}

.no-padding {
  padding: 0;
}
